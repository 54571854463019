import React, { useEffect, useState } from "react";
import useScreenWidth from "../hooks/useScreenWidth";
import Twitter from "../Assets/Twitter.png";
import ViperMobile from "../Assets/ViperMobile.png";
import ViperDesktop from "../Assets/ViperDesktop.png";
import PlanCDesktop from "../Assets/PlanCDesktop.png";

import MinecadiaDesktop from "../Assets/MinecadiaDesktop.png";

// import TweetsSample from "../Assets/TweetsSample.png";
import axios from "axios";
import { logRoles } from "@testing-library/dom";
export default function Body() {
  const screenWidth = useScreenWidth();
  const [online,setonline]=useState();
  const [unique,setunique]=useState();
  const [peak,setpeak]=useState();
  const [res,setres]=useState({})
  useEffect(()=>{
    setInterval(async() => {
      try {
        const res=await axios.get("https://api.vorexservices.net/v1/stats");
        // console.log(res["data"]["viper"]);
        setunique((res["data"]["viper"]["unique"] + res["data"]["minecadia"]["unique"]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setonline((res["data"]["viper"]["online"] + res["data"]["minecadia"]["online"]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
		setpeak((res["data"]["viper"]["peak"] + res["data"]["minecadia"]["peak"]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      } catch (error) {
        console.log(error);
      }
      console.log("mduti");
    }, 3000);
  },[]);
  
  return (
    <>
      <main>
        <section className="desc">
          <div>
            <div className="heading">what we do</div>
            <div className="content">
            Vorex serves to direct, innovate, and operate numerous unique video game servers to entertain millions of players worldwide.{" "}
            </div>
          </div>

          {/* card section */}

          <div className="card mb-3 justify-content-center">
            <div className="row g-0 justify-content-center">
              <div className="col-md-6 cardLeft">
                <div className="card-body">
                  <h5 className="card-title">Viper</h5>
                  <p className="card-text">
                  Since its re-opening in 2018, Viper has served as a backbone for the Hardcore Factions Community by repeatedly and continuously developing the standard for the quality and content within the game mode. In addition, Viper has notably remained the most popular network for Minecraft Hardcore Factions, Kit PvP, and Practice, while also continuing to expand and develop more game modes.
                    {/* {screenWidth >= 415
                      ? " libero. Lorem ipsum dolor sit amet, consectetur adipiscing"
                      : " "} */}
                  </p>
                  {/* <div> */}
                  <a href="https://vipermc.net" target="_blank">
                    <button type="button">See more</button>
                    
                  </a>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-6 cardRight">
                <img
                  src={screenWidth >= 768 ? ViperDesktop : ViperMobile}
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
		  <div className="card mb-3 pt-3 justify-content-center">
            <div className="row g-0 justify-content-center">
              <div className="col-md-6 cardLeft">
                <div className="card-body">
                  <h5 className="card-title">Minecadia</h5>
                  <p className="card-text">
                  Minecadia, founded in 2021, is a server known for its popular Minecraft game modes, such as Factions, Skyblock, Survival, and OneBlock. Since it's opening, Minecadia has quickly gained a reputation for providing fun, unique experiences on each. Notably, Minecadia's Factions realm continues to break records for the game mode, all whilst the server continues to expand and develop others.                    {/* {screenWidth >= 415
                      ? " libero. Lorem ipsum dolor sit amet, consectetur adipiscing"
                      : " "} */}
                  </p>
                  {/* <div> */}
                  <a href="https://minecadia.com/" target="_blank">
                    <button type="button">See more</button>
                    
                  </a>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-6 cardRight">
                <img
                  src={screenWidth >= 768 ? MinecadiaDesktop : MinecadiaDesktop}
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
		  <div className="card mb-3 pt-3 justify-content-center">
            <div className="row g-0 justify-content-center">
              <div className="col-md-6 cardLeft">
                <div className="card-body">
                  <h5 className="card-title">PLAN C</h5>
                  <p className="card-text">
                  Plan C is Vorex's vision to build a sustainable, entertaining, and quality environment for all players to enjoy by using the experiences, information, and values learned throughout the past few years through Vorex's expansion on Minecraft. The path Vorex intends to take with Plan C is uncharted territory, but, we take this path with confidence through the failures and mistakes we have learned and grown from, and plan to correct with Plan C.                    {/* {screenWidth >= 415
                      ? " libero. Lorem ipsum dolor sit amet, consectetur adipiscing"
                      : " "} */}
                  </p>
                  {/* <div> */}
                  <a href="https://twitter.com/vorexplanc" target="_blank">
                    <button type="button">See more</button>
                    
                  </a>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-6 cardRight">
                <img
                  src={screenWidth >= 768 ? PlanCDesktop : PlanCDesktop}
                  className="img-fluid "
                />
              </div>
			</div>
		  </div>

        </section>

        {/* stats */}

        <section className="stats d-flex justify-content-between align-items-center">
          <div>
            <span>{unique}</span>
            <span>unique players</span>
          </div>
          <div>
            <span>{online}</span>
            <span>online players</span>
          </div>
          <div>
            <span>{peak}</span>
            <span>PEAK PLAYER COUNT</span>
          </div>
        </section>
        <section className="tweets">
          <div className="heading">keep up with vorex</div>
          <div className="content">
          Vorex's creative direction can be observed most easily by keeping up with our networks' social medias, found below.
          </div>
          <div className="twitterCard">
            <a
              class="twitter-timeline"
              data-lang="en"
              href="https://twitter.com/ViperMC?ref_src=twsrc%5Etfw"
            >
              Tweets by ViperMC
            </a>
          </div>
        </section>
      </main>

      <footer>
        <div
          className={
            "d-flex container justify-content-between align-items-center"
          }
        >
          <div className="copyright">&copy;VOREX SERVICES 2022</div>
          <a href="https://twitter.com/vorexservices" target="_blank">
            <img src={Twitter} />
          </a>
        </div>
      </footer>
    </>
  );
}
