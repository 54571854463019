import React from "react";
import VorexLogoWhite from "../Assets/VorexLogoWhite.png";
import V02 from "../Assets/V02.png";
// import VorexLogoMain from "../Assets/VorexLogoMain.svg";

export default function Header() {
  return (
    <header>
      <nav>
        <img src={VorexLogoWhite} />
      </nav>
      <section>
        <img src={V02} />
        <div>Vorex Services</div>
        <div>A family business</div>
      </section>
    </header>
  );
}
